import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/router';
const axios = require('axios');
const crypto = require('crypto')
const DiscordOauth2 = require("discord-oauth2");
const oauth = new DiscordOauth2({
  clientId: "363395679084871681",
  redirectUri: `${location.origin}/callback`,
  // redirectUri: 'https://discordbot.craftycram.net/callback',
  scope: 'identify, guilds, guilds.join',
});

Vue.use(Vuex)

export default new Vuex.Store({
  state: {

    loggingIn: false,
    refreshing: false,
    isLoggedIn: false,
    authDetailsLoaded: false,
    errorOnTokens: false,
    loggedInUser: {},
    userDetails: {},
    userGuilds: [],
    botGuilds: [],
    authDetails: {},

    wandAgentConnection: null,
    wandAlreadyConnected: false,
    wandAgentConnected: false,
    wandRecieved: false,
    wandRecievedTs: 0,

  },
  mutations: {

    SET_LOGGED_IN(state, user) {
      state.loggedInUser = user;
      state.isLoggedIn = true;
      localStorage.setItem('loggedIn', Date.now());
    },
    SET_LOGGED_OUT(state) {
      state.loggedInUser = {};
      state.userDetails = {};
      state.authDetails = {};
      state.isLoggedIn = false;
      localStorage.removeItem('loggedIn');
      router.push({
        path: '/',
        query: { redirect: router.currentRoute.fullPath },
      });
    },
    SET_LOGGING_IN_STATUS(state, loggingIn) {
      state.loggingIn = loggingIn;
    },
    SET_REFRESHING_STATUS(state, refreshing) {
      state.refreshing = refreshing;
    },
    SET_USER_DETAILS_FROM_API(state, details) {
      state.detailsLoaded = true;
      state.userDetails = details;
    },
    SET_USER_GUILDS_FROM_API(state, details) {
      state.userGuilds = details;
    },
    SET_BOT_GUILDS(state, details) {
      state.botGuilds = details;
    },
    SET_AUTH_DETAILS_FROM_API(state, details) {
      state.authDetailsLoaded = true;
      state.authDetails = details;
    },
    SET_TOKEN_EXPIRED() {
      this.commit('SET_LOGGED_OUT');
      this._vm.$bvToast.toast('please login again', {
        title: 'session expired',
        variant: 'danger',
        solid: true,
        autoHideDelay: 2000,
        toaster: 'b-toaster-top-right',
        appendToast: false
      });
    },
    SET_TOKEN_ERROR(state) {
      state.errorOnTokens = true;
      state.loggingIn = false;
    },
    SHOW_TOAST(state, options) {
      this._vm.$bvToast.toast(options.text, {
        title: options.title,
        variant: options.variant,
        solid: true,
        autoHideDelay: 2000,
        toaster: 'b-toaster-top-right',
        appendToast: false
      });
    }

  },
  actions: {

    getAuthURL(context) {
      context.commit('SET_LOGGING_IN_STATUS', true);
      const url = oauth.generateAuthUrl({
        scope: ['identify', 'guilds', 'guilds.join'],
        state: crypto.randomBytes(16).toString("hex"), // Be aware that randomBytes is sync if no callback is provided
      });
      context.dispatch('openAuthUrl', { path: url });
    },

    getTokens(context, code) {
      context.commit('SET_LOGGING_IN_STATUS', true);
      const redirectUri = `${location.origin}/callback`;
      axios.post('/auth/tokens', { code, redirectUri }, { withCredentials: true, })
      .then(function (response) {
        context.commit('SET_AUTH_DETAILS_FROM_API', response.data);
        context.commit('SET_LOGGED_IN');
        context.commit('SET_LOGGING_IN_STATUS', false);
        localStorage.setItem('refresh', Date.now());
        window.close();
      })
      .catch(function (error) {
        console.log(error);
        context.commit('SET_TOKEN_ERROR');
        context.commit('SHOW_TOAST', { title: 'Error', text: 'There was an error logging you in.', variant: 'danger' });
      });
    },

    getUserDetails(context) {
      axios.get('https://discord.com/api/v8/oauth2/@me', {
        headers: {
          Authorization: `Bearer ${context.state.authDetails.access_token}`,
        }
      })
      .then(function (response) {
        context.commit('SET_USER_DETAILS_FROM_API', response.data);
        context.dispatch('sendWandAgentStatus', 'userDetail');
      })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          context.commit('SET_TOKEN_EXPIRED');
        }
      });
    },

    getUserGuilds(context) {
      axios.get('https://discord.com/api/v8/users/@me/guilds', {
        headers: {
          Authorization: `Bearer ${context.state.authDetails.access_token}`,
        }
      })
      .then(function (response) {
        context.commit('SET_USER_GUILDS_FROM_API', response.data);
        context.dispatch('getBotGuilds', response.data);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          context.commit('SET_TOKEN_EXPIRED');
        }
      });
    },

    getBotGuilds(context, userGuilds) {
      axios.post('/user/guilds', { userGuilds }, {  
        headers: {
          Authorization: `Bearer ${context.state.authDetails.access_token}`
        }
      })
      .then(function (response) {
        context.commit('SET_BOT_GUILDS', response.data);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          context.commit('SET_TOKEN_EXPIRED');
        }
      });
    },

    refreshTokens(context, loggedIn = false) {
      if (context.state.isLoggedIn !== true) return;
      if (!loggedIn) context.commit('SET_LOGGING_IN_STATUS', true);
      if (loggedIn) context.commit('SET_REFRESHING_STATUS', true);
      axios.get('/auth/refresh', { withCredentials: true, })
      .then(function (response) {
        context.commit('SET_AUTH_DETAILS_FROM_API', response.data);
        context.commit('SET_LOGGED_IN');
        if (!loggedIn) context.commit('SET_LOGGING_IN_STATUS', false);
        if (loggedIn) context.commit('SET_REFRESHING_STATUS', false);
        localStorage.setItem('loggedIn', Date.now());
        context.dispatch('getUserDetails');
        context.dispatch('getUserGuilds');
        const redirect = router.currentRoute.query.redirect;
        if (redirect) router.push(redirect);
        setTimeout(() => context.dispatch('refreshTokens', true), response.data.expires_in);
      })
      .catch(function (error) {
        console.log(error);
        context.commit('SET_LOGGING_IN_STATUS', false);
        context.commit('SET_REFRESHING_STATUS', false);
        if (context.state.isLoggedIn) context.commit('SET_TOKEN_EXPIRED');
        const redirect = router.currentRoute.query.redirect;
        if (redirect) context.commit('SHOW_TOAST', { title: 'Login required', text: 'Please login to get back where you wanted to go.', variant: 'danger' });
      });
    },

    logout(context) {
      axios.get('/auth/logout', { withCredentials: true, })
      .then(function (response) {
        if (response.data === true) {
          context.commit('SHOW_TOAST', { title: 'Sucess', text: 'You\'re now logged out.', variant: 'info' });
          context.commit('SET_LOGGED_OUT')
          context.dispatch('sendWandAgentStatus', 'logout');
          return;
        }
        context.commit('SHOW_TOAST', { title: 'Error', text: 'There was an error logging you out.', variant: 'danger' });
      })
      .catch(function (error) {
        console.log(error);
        context.commit('SHOW_TOAST', { title: 'Error', text: 'There was an error logging you out.', variant: 'danger' });
      });
    },

    openAuthUrl(context, options) {
      options.windowName = options.windowName ||  'ConnectWithOAuth'; // should not include space for IE
      options.windowOptions = options.windowOptions || 'location=0,status=0,width=800,height=1000';
      options.callback = options.callback || function(){ window.location.reload(); };
      var that = this;
      // console.log(options.path);
      that._oauthWindow = window.open(options.path, options.windowName, options.windowOptions);
      that._oauthInterval = window.setInterval(function(){
          if (that._oauthWindow.closed) {
              window.clearInterval(that._oauthInterval);
              options.callback();
          }
      }, 1000);
    },

    connectToWandAgent(context) {
      context.state.wandAgentConnection = new WebSocket('ws://localhost:3123')
      context.state.wandAgentConnection.onmessage = (event) => {
        context.state.wandRecieved = true;
        if (Date.now() - context.state.wandRecievedTs > 5000) {
          context.state.wandRecievedTs = Date.now();
          setTimeout(() => context.state.wandRecieved = false, 1000);
          console.log(event);
          axios.get(`/sounds/play?sound=${event.data}`, {
            headers: {
              Authorization: `Bearer ${context.state.authDetails.access_token}`
            }
          }).catch((err) => {
            if (err.response.status === 400) {
              context.commit('SHOW_TOAST', { title: 'Error', text: 'Couldn\'t find you in a voice channel.', variant: 'danger' });
            }
            if (err.response.status === 401) {
              context.commit('SET_TOKEN_EXPIRED');
            }
          });
        }
      }
      context.state.wandAgentConnection.onopen = () => {
        context.commit('SHOW_TOAST', { title: 'WandAgent', text: 'Connected to WandAgent', variant: 'success' });
        console.log('Connected to Wand Agent');
        context.dispatch('sendWandAgentStatus', 'connect');
        context.state.wandAgentConnected = true;
        context.state.wandAlreadyConnected = true;
      }
      context.state.wandAgentConnection.onclose = () => {
        if (context.state.wandAlreadyConnected === true) context.commit('SHOW_TOAST', { title: 'WandAgent', text: 'Disconnected from WandAgent.', variant: 'warning' });
        console.log('Disconnected from Wand Agent');
        context.state.wandAgentConnected = false;
        setTimeout(() => context.dispatch('sendWandAgentStatus', 'retry'), 10000);
      }
      context.state.wandAgentConnection.onerror = () => {
        if (context.state.wandAlreadyConnected === true) context.commit('SHOW_TOAST', { title: 'WandAgent', text: 'Error connecting to WandAgent.', variant: 'danger' });
        console.log('Error connecting to Wand Agent');
        context.state.wandAgentConnected = false;
        setTimeout(() => context.dispatch('sendWandAgentStatus', 'retry'), 10000);
      }
    },
    sendWandAgentStatus(context, reason) {
      const data = {};
      data.mode = 'setStore';
      data.reason = reason;
      data.loggedIn = context.state.isLoggedIn;
      if (context.state.userDetails.user) data.avatar = context.state.userDetails.user.avatar ? `https://cdn.discordapp.com/avatars/${context.state.userDetails.user.id}/${context.state.userDetails.user.avatar}.png?size=32` : `https://cdn.discordapp.com/embed/avatars/${context.state.userDetails.user.discriminator%5}.png?size=32`;
      if (context.state.userDetails.user) data.username = context.state.userDetails.user.username;
      if (context.state.userDetails.user) data.discriminator = context.state.userDetails.user.discriminator;
      context.state.wandAgentConnection.send(JSON.stringify(data));
    }

  },
  modules: {}
})