import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router/router'
import axios from 'axios';
import VueAxios from 'vue-axios'
import store from './store/store'
import VueClipboard from 'vue-clipboard2'

import firebase from "firebase/app";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDIdV-s5lmulzw5VG9iQWh1IEZYyhabTMA",
  authDomain: "discordbotjs-1591708566192.firebaseapp.com",
  projectId: "discordbotjs-1591708566192",
  storageBucket: "discordbotjs-1591708566192.appspot.com",
  messagingSenderId: "773514281686",
  appId: "1:773514281686:web:60ea71c985e767bc54ff77",
  measurementId: "G-SDJ59K1BP9"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
Vue.prototype.$analytics = firebase.analytics();

Vue.use(VueClipboard);

Vue.use(VueAxios, axios)
axios.defaults.baseURL = 'https://discord.glados.itmr-dev.de/'

Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    // shows popup status messages
    toast: function (title, text, variant = null) {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
        autoHideDelay: 2000,
        toaster: 'b-toaster-top-right',
        appendToast: false
      })
    },
  }
});

router.beforeEach((to, from, next) => {
  firebase.analytics().logEvent('route_change', {from: from.fullPath, to: to.fullPath});
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.authDetails.access_token === undefined) {
      // console.log(to);
      // store.dispatch('getAuthURL');
      if (!store.state.loggingIn) next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
      setTimeout(() => {
        if (!store.state.loggingIn && !store.state.isLoggedIn) {
          store.commit('SHOW_TOAST', { title: 'Login required', text: 'Please login to view that Page.', variant: 'danger' });
          store.state.isLoggedIn = false;
        }
      }, 1000);
    } else {
      next();
      store.state.isLoggedIn = true;
    }
  } else if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (store.state.userDetails.user) {
      if (store.state.userDetails.user.id !== '305805935203581963') {
        // console.log(to);
        next({
          path: '/',
        });
        store.commit('SHOW_TOAST', { title: 'Error', text: 'You\'re not permitted to view this page.', variant: 'danger' });
      } else {
        next();
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
