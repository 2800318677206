<template>
  <b-container>
    <b-row><h1 @click="test">Welcome to the craftycram.net discord bot dashboard</h1></b-row>
  </b-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  },
  methods: {
    test() {
      this.$store.dispatch('refreshTokens', true);
    },
  },
  mounted() {
  }
}
</script>
