import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import AppHeader from '../components/AppHeader.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    components: {
      header: AppHeader,
      default: Home,
    }
  },
  {
    path: '/commands',
    name: 'Commands',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "about" */ '../views/Commands.vue'),
    }
  },
  {
    path: '/invite',
    name: 'Invite',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Invite.vue')
  },
  {
    path: '/sounds',
    name: 'Sounds',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "about" */ '../views/Sounds.vue'),
    }
  },
  {
    path: '/guilds',
    name: 'Guilds',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "about" */ '../views/Guilds.vue'),
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/manage',
    name: 'Manage',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "about" */ '../views/Manage.vue'),
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/serverlog',
    name: 'ServerLog',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "about" */ '../views/ServerLog.vue'),
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue'),
    },
    meta: {
      requiresAdmin: true,
    }
  },
  {
    path: '/login',
    name: 'Login',
    components: {
      header: AppHeader,
      default: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    },
  },
  {
    path: '/callback',
    name: 'Callback',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default: () => import(/* webpackChunkName: "about" */ '../views/Callback.vue'),
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
