<template>
  <b-navbar toggleable="lg" type="dark" variant="dark" :sticky="true" v-if="$store.state.loggingIn === false">
    <b-navbar-brand to="/">
      <img src="@/assets/logo.png" alt="icon" width="30">
      craftycram.net Discord Bot
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item to="/">Home</b-nav-item>
        <b-nav-item to="/commands">Commands</b-nav-item>
        <b-nav-item to="/sounds">Sounds</b-nav-item>
        <b-nav-item to="/guilds">Guilds</b-nav-item>
        <b-nav-item to="/admin" v-if="$store.state.userDetails && $store.state.userDetails.user && $store.state.userDetails.user.id==='305805935203581963'">Admin</b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown text="Lang" right v-if="false">
          <b-dropdown-item href="#">EN</b-dropdown-item>
          <b-dropdown-item href="#">ES</b-dropdown-item>
          <b-dropdown-item href="#">RU</b-dropdown-item>
          <b-dropdown-item href="#">FA</b-dropdown-item>
        </b-nav-item-dropdown>

        <p class="text wandTimeout margin max top bottom">{{wandTimeout}}</p>
        <img v-if="$store.state.wandAlreadyConnected" id="tt-wand" :class="wandClasses" src="@/assets/wand.svg" alt="" srcset="">
        <b-tooltip target="tt-wand" triggers="hover">
          <span v-if="$store.state.isLoggedIn">Connected to WandAgent</span>
          <span v-if="!$store.state.isLoggedIn">Not logged in</span>
        </b-tooltip>
        
        <b-nav-form>
          <b-button variant="outline-secondary" href="https://discord.com/oauth2/authorize?client_id=363395679084871681&scope=bot&permissions=2146958711" target="_blank">Add Bot</b-button>
        </b-nav-form>
        <b-nav-item/>
        <b-nav-item/>
        <b-nav-item right @click="triggerLogin()" v-if="!$store.state.isLoggedIn">
          Login
        </b-nav-item>
        <b-nav-item-dropdown right v-if="$store.state.isLoggedIn">
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <div style="display: inline-flex">
              <div class="inline-flex maximized row" style="padding-right: 1rem;">
                <div class="inline-flex minimized">
                  <div class="inline-flex minimized margin max top bottom">
                    <span v-if="$store.state.userDetails.user">
                      <div :class="{ loader: true, loaderSpin: refreshing }"></div>
                      <img class="avatar" :src="$store.state.userDetails.user.avatar ? `https://cdn.discordapp.com/avatars/${$store.state.userDetails.user.id}/${$store.state.userDetails.user.avatar}.png?size=32` : `https://cdn.discordapp.com/embed/avatars/${$store.state.userDetails.user.discriminator%5}.png?size=32`"/>
                      {{$store.state.userDetails.user.username}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <b-dropdown-item @click="$store.dispatch('connectToWandAgent')">Reconnect WandAgent</b-dropdown-item>
          <b-dropdown-item href="https://github.com/craftycram" target="_blank">Download WandAgent</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="joinGuild()">Join our Server</b-dropdown-item>
          <b-dropdown-item @click="triggerLogout()">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item/>
        <b-nav-item/>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
  export default {
    name: 'AppHeader',
    data() {
      return {
        refreshing: false,
        now: Date.now(),
      };
    },
    mounted() {
      setInterval(() => {
        this.now = Date.now();
      }, 500);
    },
    methods: {
      triggerLogin() {
        this.$store.dispatch('getAuthURL');
      },
      triggerLogout() {
        this.$store.dispatch('logout');
      },
      joinGuild() {
        this.axios.get('joinCcNet', {
          headers: {
            Authorization: `Bearer ${this.$store.state.authDetails.access_token}`
          }
        }).then(() => this.toast('sucess', 'you were successfully added to the craftycram dev discord server',
          'success')).catch((err) => {
          if (err.response.status === 401) {
            this.$store.commit('SET_TOKEN_EXPIRED');
          }
        });
      },
    },
    computed: {
      refreshingState() {
        return this.$store.state.refreshing;
      },
      wandClasses() {
        const classes = { wand: true,
        filterYellow: this.$store.state.wandAlreadyConnected && !this.$store.state.wandAgentConnected,
        filterGreen: this.$store.state.isLoggedIn && this.$store.state.wandAlreadyConnected,
        filterRed: !this.$store.state.isLoggedIn && this.$store.state.wandAgentConnected,
        filterGray: (this.now - this.$store.state.wandRecievedTs) < 5000,
        shake: this.$store.state.wandRecieved === true,
        margin: true,
        max: true,
        top: true,
        bottom: true }
        return classes;
      },
      wandTimeout() {
        if ((this.now - this.$store.state.wandRecievedTs) > 5000) return '';
        return 5 - Math.floor((this.now - this.$store.state.wandRecievedTs) / 1000);
      },
    },
    watch: {
      refreshingState() {
        if (this.refreshingState === true) {
          this.refreshing = true;
          setTimeout(() => {
            this.refreshing = false;
          }, 4000);
        }
      },
    }
  }
</script>

<style scoped>

.inline-flex {
  flex: 1 1 auto;
  display: inline-flex !important;
  flex-flow: column;
}

.inline-flex.row {
    flex-flow: row !important;
  }

.inline-flex.column {
    flex-flow: column !important;
  }

.inline-flex.minimized{
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
  }

.inline-flex.maximized{
    flex-grow: 1 !important;
    flex-shrink: 0 !important;
  }

.margin.max.top {
  margin-top: auto !important;
}

.margin.max.bottom {
  margin-bottom: auto !important;
}

.margin.max.left {
  margin-left: auto !important;
}

.margin.max.right {
  margin-right: auto !important;
}

.buttonWrapper {
}

.wandTimeout {
  margin-right: 1rem;
}

.avatarWrapper {
  position: relative;
  width: 2rem;
  height: 2rem;
}

.avatar {
  height: 2rem;
  /*
  position: absolute;
  z-index: 1;
  */
}

.loader {
  color: transparent;
  position: absolute;
  margin: 0px;
  width: 2rem;
  height: 2rem;
  z-index: 2;
}

.loaderFade {
  border: 16px solid #57F287; /* Light grey */
  border-radius: 50%;
  animation: fade 2s linear infinite;
}
.loaderSpin {
  border: 3px dashed #57F287;
  /* border: 3px solid transparent; /* Light grey */
  /*border-top: 3px solid #57F287; /* Blue */
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes fade {
  0% { opacity: 0%; }
  50% { opacity: 100%; }
  100% { opacity: 0%; }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.wand {
  position: relative;
  color: #57F287;
  width: 2rem;
  height: 2rem;
  margin-right: 2rem;
}

.filterGreen{
  filter: invert(100%) sepia(23%) saturate(6562%) hue-rotate(58deg) brightness(117%) contrast(90%);
}
.filterYellow{
  filter: invert(89%) sepia(75%) saturate(564%) hue-rotate(323deg) brightness(101%) contrast(113%);
}
.filterRed{
  filter: invert(35%) sepia(25%) saturate(7471%) hue-rotate(335deg) brightness(94%) contrast(98%);
}
.filterGray{
  filter: invert(70%) sepia(4%) saturate(1015%) hue-rotate(160deg) brightness(94%) contrast(93%);
}

</style>