<template>
  <b-container>
    <p class="loginInfo">Logging you in… Please wait</p>
  </b-container>
</template>

<script>
export default {
  name: 'LoggingInOverlay'
}
</script>

<style>
.loginInfo {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: xx-large;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>