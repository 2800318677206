<template>
  <div id="app">
    <router-view name="header"></router-view>
    <main>
      <fade-transition origin="center" mode="out-in" :duration="250">
        <router-view style="min-height: 90vh; margin-top: 2rem;" v-if="$store.state.loggingIn === false || $router.currentRoute.query.code" />
        <LoggingInOverlay v-if="$store.state.loggingIn === true && !$router.currentRoute.query.code" />
      </fade-transition>
    </main>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
  import {
    FadeTransition
  } from "vue2-transitions";
  import LoggingInOverlay from '@/components/LoggingInOverlay.vue';
  export default {
    name: 'App',
    components: {
      FadeTransition,
      LoggingInOverlay
    },
    data() {
      return {
      };
    },
    mounted() {
      this.$analytics.logEvent('page_load');
      setInterval(() => {
        if (this.$store.state.wandAgentConnected) this.$store.dispatch('sendWandAgentStatus', 'ping');
      }, 1000);
      if (this.$router.currentRoute.query.code) return this.$router.push({
        path: '/callback',
        query: {
          code: this.$router.currentRoute.query.code
        }
      });
      window.addEventListener('storage', this.storageEvents)
      const loggedIn = localStorage.getItem('loggedIn');
      this.$store.state.isLoggedIn = loggedIn !== null;
      this.$store.dispatch('connectToWandAgent');
      if (loggedIn !== null) return this.$store.dispatch('refreshTokens');
      const redirect = this.$router.currentRoute.query.redirect;
      if (redirect) this.$router.push(redirect);
    },
    methods: {
      storageEvents(e) {
        if (e.key === 'refresh') {
          console.log('refreshed from storage!');
          // setTimeout(() => location.reload(), 1000);
          location.reload();
        }
      },
    }
  }
</script>

<style>

body {
  background-color: #323842 !important;
  color: #d1d1d1;
}

.bg-dark {
  background-color: #3d4149 !important;
}

.avatar {
  border-radius: 50%;
}

.shake {
  animation: shake 0.5s;
}

.b-toaster-top-right {
  top: 4rem !important;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}
</style>